header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.HeaderRight {
    line-height: 40px;
}

.NotificationIcon {
    display: block;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 60px;
    position: relative;
}

.BellIcon {
    color: #231F20;
    font-size: 25px;
}

@media only screen and (max-width: 525px) {

    .NotificationIcon {
        width: 40px;
        height: 40px;
        line-height: 50px;
    }

    .BellIcon {
        font-size: 20px;
    }
  }