nav {
  width: 100px;
  height: 100vh;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
}

.nav-img {
  width: 3em;
  padding-top: 40px;
}

nav ul {
  padding-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

nav ul li {
  list-style: none;
  margin: 20px 0;
  position: relative;
  width: 100%;
  text-align: center;
}

.nav-icon {
  font-size: 28px;
  color: #8c8a95;
  text-decoration: none;
  transition: all 0.3s;
}

.nav-icon path {
  stroke: #8c8a95;
}

.nav-icon:hover {
  color: #3477d4;
}

.nav-icon:hover path {
  stroke: #3477d4;
}

.nav-bot-icon {
  font-size: 28px;
  color: #8c8a95;
  text-decoration: none;
  transition: all 0.3s;
}

.nav-bot-icon:hover {
  color: #3477d4;
}

.botNav {
  margin-top: auto;
  margin-bottom: 10px;
}

.active path {
  stroke: #0049ad;
}

@media only screen and (max-width: 525px) {
  nav {
    width: 60px;
  }
  
  .nav-img {
    width: 1.5em;
  }

  .nav-icon {
    font-size: 20px;
  }
  
  .nav-bot-icon {
    font-size: 20px;
  }
}