.App {
  width: 100%;
  background: #ffffff;
  display: flex;
  overflow: auto;
}

.App::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 600px) {
  .App {
    overflow-x: hidden;
    overflow-y: scroll;
  }
}