.MainContainer {
  width: calc(100% - 100px);
  background: #ffffff;
  padding: 40px;
  padding-top: 40px;
  margin-left: 100px;
}

@media only screen and (max-width: 525px) {
  .MainContainer {
    width: calc(100% - 60px);
    padding: 20px;
    padding-top: 20px;
    margin-left: 60px;
  }
}